<template>
  <Button icon="google" class="btn primary f-bold f-lg block mt-16" :class="{line: !primary}" @click="login">
    <t path="signinup_25" #="{td}" custom>{{ td || 'Sign In with Google' }}</t>
  </Button>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { defineComponent, watch } from 'vue'
import { useGoogleLogin, islamFlag } from '@/features/googleLogin/googleLogin.fns'

export default defineComponent({
  name: 'GoogleLoginBtn',
  components: { Button },
  props: {
    primary: Boolean,
    islamFlag: Boolean,
  },
  setup (props) {
    const login = useGoogleLogin()
    watch(() => props.islamFlag, () => {
      islamFlag.value = props.islamFlag
    })
    return {
      login,
    }
  },
})
</script>
