/**
* @author ayou[beica1@outook.com]
* @date 2022/1/27 15:45
* @description
*   LoginWithEmail.vue of WeTrade
*/
<template>
<PageWithHeader class="px-16">
  <template #title>
    <t path="signinemail_2"></t>
  </template>
  <t custom #="{t}">
    <Form ref="form" :schema="emailLoginSchema" class="p-16" @submit="login">
      <FormInput name="email" :placeholder="t('signinemail_3')" />
      <ThePasswordInput :placeholder="t('signinemail_4')" />
    </Form>
  </t>
  <template #footer>
    <Button class="primary br-lg f-bold f-lg" :progress="progress" @click="submit">
      <t path="signinemail_5"></t>
    </Button>
    <GoogleLoginBtn class="br-lg" />
    <div class="wedge" style="height: 40px"></div>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import { emailLoginSchema, useLogin } from '@/pages/login/login'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Form from 'common/form/Form.vue'
import FormInput from 'common/form/inputs/FormInput.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'LoginWithEmail',
  components: { GoogleLoginBtn, Button, ThePasswordInput, FormInput, Form, PageWithHeader },
  setup () {
    const form = shallowRef()
    const { login, progress } = useLogin(true)
    const countryCode = shallowRef(useRoute().query.countryCode)

    return {
      login,
      progress,
      submit () {
        if (form.value) {
          form.value.values.countryCode = countryCode.value
        }
        (form.value as HTMLFormElement).submit()
      },
      form,
      emailLoginSchema,
    }
  },
})
</script>

<style scoped>

</style>
