/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   Password.vue of WeTrade
*/
<template>
  <Input :type="type">
    <template #addon>
      <slot name="addon" :toggle="toggle" :visible="type === 'text'" />
    </template>
  </Input>
</template>

<script lang="ts">
import Input from 'common/form/inputs/FormInput.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'Password',
  components: { Input },
  setup () {
    const type = shallowRef('password')

    const toggle = () => {
      const next = Number(type.value !== 'password')
      type.value = ['text', 'password'][next]
    }

    return {
      type,
      toggle,
    }
  },
})
</script>
