/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   ThePasswordInput.vue of WeTrade
*/
<template>
  <t path="signinup_17" #="{td}" as="div" custom>
    <Password name="password" :placeholder="td || 'Password'" v-bind="$attrs">
      <template #addon="{ toggle, visible }">
        <Icon :name="visible ? 'eye-open' : 'eye-closed'" class="c-primary" @click="toggle" />
      </template>
    </Password>
  </t>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Password from 'common/form/inputs/Password.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePasswordInput',
  components: { Icon, Password },
})
</script>
