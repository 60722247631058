
import Button from '@/components/Button.vue'
import GoogleLoginBtn from '@/features/googleLogin/GoogleLoginBtn.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import { emailLoginSchema, useLogin } from '@/pages/login/login'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Form from 'common/form/Form.vue'
import FormInput from 'common/form/inputs/FormInput.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'LoginWithEmail',
  components: { GoogleLoginBtn, Button, ThePasswordInput, FormInput, Form, PageWithHeader },
  setup () {
    const form = shallowRef()
    const { login, progress } = useLogin(true)
    const countryCode = shallowRef(useRoute().query.countryCode)

    return {
      login,
      progress,
      submit () {
        if (form.value) {
          form.value.values.countryCode = countryCode.value
        }
        (form.value as HTMLFormElement).submit()
      },
      form,
      emailLoginSchema,
    }
  },
})
