/**
* @author 贝才[beica1@outook.com]
* @date 2020/10/28
* @description
*   Input.vue of FastTrade
*/
<template>
  <FormItem #="{ update, mode, attrs, value }">
    <slot name="prefix" :value="value"/>
    <div class="flex-1">
      <input
        v-bind="attrs" :type="type" :value="value" autocomplete="off"
        style="width: 100%;"
        class="input" v-on:[mode]="update($event.target.value)"
      />
    </div>
    <slot name="addon" :value="value" :update="update" />
  </FormItem>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FormItem from '../FormItem.vue'

export default defineComponent({
  name: 'FormInput',
  components: { FormItem },
  props: {
    type: {
      type: String,
      require: false,
    },
  },
})
</script>
