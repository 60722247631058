/**
* @author 贝才[beica1@outook.com]
* @date 2020/10/28
* @description
*   Form.vue of FastTrade
*/
<template>
  <form
    :class="$attrs.class" :style="$attrs.style" class="form" @update="update"
    @submit.prevent="submit"
  >
    <slot :values="values" :submit="submit" />
  </form>
</template>

<script lang="ts">
import * as R from 'ramda'
import { defineComponent, toRaw, provide, shallowRef } from 'vue'
import Schema, { ObjectPropsOptions, SchemaStates } from './Schema'

export default defineComponent({
  inheritAttrs: false,
  name: 'Form',
  props: {
    schema: {
      type: Object,
      required: true,
    },
    syncMode: {
      type: String,
      default: 'input', // change | input
      validator: (value: string) => {
        return (value === 'change' || value === 'input')
      },
    },
  },
  emits: ['submit'],
  setup (props, ctx) {
    const state = shallowRef({})
    const schema = new Schema(props.schema as ObjectPropsOptions)

    /**
     * 表单验证
     * @param key
     */
    const validate = (key?: string): [SchemaStates, boolean] => {
      const states = schema.validate(key)
      const errors = R.filter(R.propEq('valid', false), states)
      return [errors as SchemaStates, R.isEmpty(errors)]
    }

    /**
     * 表单状态更新
     * @param errors
     */
    const sync = (errors: SchemaStates) => {
      state.value = errors
    }

    /**
     * 表单值更新
     * @param e
     */
    const update = (e: { detail: { key?: string } }) => {
      const [errors] = validate(e.detail?.key)
      sync(errors)
    }

    /**
     * 表单提交
     */
    const submit = () => {
      const [errors, valid] = validate()
      if (valid) {
        const data = toRaw(schema.values)
        ctx.emit('submit', data)
      } else {
        sync(errors)
      }
    }

    provide('values', schema.values)
    provide('state', state)
    provide('mode', props.syncMode)

    return { submit, update, values: schema.values }
  },
})
</script>
