/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/1
 * @description
 *   login.ts of WeTrade
 */
import { withDeviceIdentities } from '@/modules/app/app'
import { login } from '@/modules/user/user'
import useRequest from '@/hooks/useRequest'
import { User } from '@/modules/user/user.api'
import { loginWithEmail, requestLogin } from '@/pages/login/login.api'
import { useRouter } from 'vue-router'

const passwordSchema = {
  password: {
    pattern: /\w{6,}/,
    default: '',
  },
}

export const loginFormSchema = {
  countryCode: {
    pattern: 'cc',
    default: '91',
  },
  mobile: {
    pattern: /^\d{8,12}$/,
    default: '',
  },
  ...passwordSchema,
}

export const emailLoginSchema = {
  email: {
    pattern: 'email',
  },
  ...passwordSchema,
}

export const useLogin = (withEmail = false) => {
  const [request, progress] = useRequest(withEmail ? loginWithEmail : requestLogin)
  const router = useRouter()

  const onLogin = (user?: User) => {
    if (user) {
      login(user)
      router.replace('/')
    }
  }

  const _login = (data: Data) =>
    withDeviceIdentities((ids: Data) => {
      return request({
        ...data,
        ...ids,
      })
        .then(onLogin)
    })

  return {
    login: _login,
    progress,
  }
}
